import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

const PresentationPage = () => (
  <>
    <StaticQuery
      query={graphql`
        query {
          markdownRemark(frontmatter: { path: { eq: "/dosarulelectronic" } }) {
            html
          }
        }
      `}
      render={(data) => {
        const { html } = data.markdownRemark;
        return (
          <section
            className='section--markdown'
            dangerouslySetInnerHTML={{ __html: html }}
          />
        );
      }}
    />
  </>
);

export default PresentationPage;
